import {ENC, RE, Logo, OFoxLogo} from './images'
import './App.css';
import './AppMobile.css';
import './fonts/index.css';
import {useEffect, useState} from "react";

function App() {
    let [show, setShow] = useState(true);
    // let [newSpins, setNewSpins] =
    let [spins, setSpins] = useState(parseInt(getCookie('spins')));
    let animEls = document.getElementsByClassName('anim');

    function showText(shouldShow) {
        if (!shouldShow) {
            for (let i = 0; i < animEls.length; i++) {
                animEls[i].classList.remove("reveal")
                animEls[i].classList.add("hide")
                window.setTimeout(function () {
                    animEls[i].classList.remove("hide")
                }, 1250);
            }
        } else {
            for (let i = 0; i < animEls.length; i++) {
                animEls[i].classList.remove("hide")
                animEls[i].classList.add("reveal")
            }
        }
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return 0;
    }

    useEffect(() => {
        document.cookie = "spins=" + spins;
        console.log(spins)
        if (spins >= 10) {
            let text = document.getElementsByClassName('noOfSpins')[0];
            text.classList.remove("reveal")
            text.classList.add('reveal');
        }

        if (spins >= 2000) {

        }
    }, [spins])

    return (
        <div className="App">
            <h2 id='noOfSpins' className='noOfSpins noSelect'>YOU'VE SPUN {spins} TIMES</h2>
            {/*<h2 id='noOfSpins' className='noOfSpins noSelect'>YOU'VE SPUN {spins} TIMES</h2>*/}
            <div className='landingDiv'>
                <div className='logoDiv'>
                    <img id={'enc'} src={ENC} className="App-logo-text anim noSelect"  alt={'Letters ENC'}/>
                    <img id={'x'} onClick={() => {
                        setSpins(spins + 1);
                        // if (spins <= 5) {
                        setShow(!show);
                        showText(show);
                        // }

                    }} src={Logo} className="App-logo anim noSelect"  alt={'Encxre mini-logo'}/>
                    <img src={RE} className="App-logo-text anim noSelect"  alt={'Letters RE'}/>
                </div>
                <h2 id='comingSoon' className='comingSoon anim noSelect'>COMING SOON</h2>
            </div>
            <img onClick={() => {window.open("https://ofox.co.uk", "_blank")}} className={'portfolioLogo anim noSelect'} src={OFoxLogo}  alt={"Logo for O. Fox portfolio website"}/>
        </div>
    );
}

export default App;
